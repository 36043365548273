<template>
  <Banner bg-image="blue-bg-2.jpg" talk-img="find-someone.png">
    <template v-slot:title>Get in touch with us</template>
    <template v-slot:subtitle>
      The team at the Miskanawah Healing Lodge is available to help, whenever
      you need it.
    </template>
  </Banner>

  <section class="py-10">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
        Miskanawah Healing Lodge is here for you
      </div>
      <p class="mb-6">
        The Healing Lodge at Miskanawah is a safe and caring healing environment
        designed specifically for Indigenous people. We offer culturally
        appropriate counselling services and programs to Individuals and
        families in a way that incorporates Indigenous values, traditions and
        beliefs. The Healing Lodge counsellor/therapist provides a space to get
        help and support without judgment. Our team will help provide tools and
        strategies to help you work towards a healthy and happy future.
      </p>
      <p class="mb-6">
        The Healing Lodge team understands that everyone faces their own unique
        challenges, and they will work with you to design a plan that meets your
        needs and desires for wellness. The Healing Lodge can help with:
      </p>
      <div class="flex flex-wrap">
        <ul>
          <li>Problem-solving</li>
          <li>Anxiety</li>
          <li>Depression</li>
          <li>Loss & Grief Trauma</li>
        </ul>
        <ul>
          <li>PTSD</li>
          <li>Information and referrals</li>
          <li>Alcohol and drug-related issues</li>
          <li>Promoting healthy relationships</li>
        </ul>
        <ul>
          <li>A safe place to talk</li>
          <li>Accessing community resources</li>
          <li>Improving family relationships</li>
          <li>Developing a safety plan</li>
        </ul>
      </div>
    </div>
  </section>

  <section class="">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
        Reach out to us
      </div>
      <p class="mb-6">
        Wiichihew has been specifically designed with input from the Healing
        Lodge clinicians at Miskanawah, Indigenous youth, elders, and
        individuals with lived experience of suicide.
      </p>
      <p>
        You can reach out to us directly if you are having thoughts of suicide
        or if you are concerned about someone else who may be considering
        suicide. Call, email or stop by the Healing Lodge anytime. You can also
        set an appointment.
      </p>
    </div>
  </section>

  <section class="py-10 contact-block">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div class="call">
          <div class="font-bold call-ttl text-blue">Healing Lodge</div>
          <div class="text-lg font-bold text-blue md:text-xl">Address</div>
          <span class="align-center text-blue">
            <div class="block">2716 Sunridge Way NE</div>
            <div class="block">Calgary, Alberta</div>
            <div>T1Y 0A5</div>
          </span>
          <br />
          <div class="text-lg font-bold text-blue md:text-xl">Email</div>
          <a
            href="mailto:healinglodge@miskanawah.ca"
            class="flex align-center text-blue"
          >
            <span>healinglodge@miskanawah.ca</span>
          </a>
          <br />
          <div class="text-lg font-bold text-blue md:text-xl">
            Phone numbers:
          </div>
          <a href="tel:4032475003" class="flex align-center text-blue">
            <span>403-247-5003</span>
          </a>
          <a href="tel:5872166163" class="flex align-center text-blue">
            <span>587-216-6163</span>
          </a>
          <a href="tel:4038300786" class="flex align-center text-blue">
            <span>403-830-0786</span>
          </a>
          <a href="tel:4036169674" class="flex align-center text-blue">
            <span>403-616-9674</span>
          </a>
        </div>
        <div class="call">
          <div class="font-bold call-ttl text-blue">Set an appointment</div>
          <!-- FORM -->
          <form @submit.prevent="onSubmit">
            <div class="mb-6 form-group">
              <label class="block text-lg text-blue md:text-xl">Name:</label>
              <input
                required
                v-model="form.name"
                type="text"
                class="w-full px-4 py-2 form-input"
              />
            </div>
            <div class="mb-6 form-group">
              <label class="block text-lg text-blue md:text-xl">Email:</label>
              <input
                required
                type="email"
                v-model="form.email"
                class="w-full px-4 py-2 form-input"
              />
              <div
                class="flex items-center justify-end mt-2 text-base text-blue"
              >
                <label class="text-sm sm:text-base"
                  >Prefer to be contacted by email</label
                >
                <input
                  v-model="form.checked"
                  type="checkbox"
                  value="email"
                  class="w-4 h-4 ml-3 border-0"
                />
              </div>
            </div>
            <div class="mb-6 form-group">
              <label class="block text-lg text-blue md:text-xl"
                >Phone number:</label
              >
              <input
                type="text"
                v-model="form.phone"
                class="w-full px-4 py-2 form-input"
              />
              <div
                class="flex items-center justify-end mt-2 text-base text-blue"
              >
                <label class="text-sm sm:text-base"
                  >Prefer to be contacted by phone</label
                >
                <input
                  v-model="form.checked"
                  type="checkbox"
                  value="phone"
                  class="w-4 h-4 ml-3 border-0"
                />
              </div>
            </div>
            <div class="mb-6 form-group">
              <label class="block text-lg text-blue md:text-xl"
                >How can we help?</label
              >
              <textarea
                v-model="form.message"
                class="w-full px-4 py-2 form-input"
                required
              ></textarea>
            </div>

            <button class="flex items-center justify-between btn btn-green">
              Submit &nbsp; &nbsp; &nbsp;
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.922"
                  height="28"
                  viewBox="0 0 10.922 28"
                  class="inline-block ml-1.5 opacity-50"
                >
                  <path
                    id="Path_4072"
                    data-name="Path 4072"
                    d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                    transform="translate(-831.098 -480.029)"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </button>
            <p class="text-red">{{ error }}</p>
          </form>
          <!-- END FORM -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Banner from "@/components/Banner.vue";
export default {
  name: "GetInTouchWithUs",
  components: {
    Banner,
  },
  data() {
    return {
      error: null,
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
        checked: [],
      },
    };
  },
  methods: {
    async onSubmit() {
      console.log(Date(Date.now()) + ": " + this.form.name);
      // ===== API CALL =====
      try {
        await axios.post("/.netlify/functions/sendmail", {
          senderEmail: this.form.email,
          senderPhone: this.form.phone,
          senderName: this.form.name,
          senderPrefer: this.form.checked,
          senderMessage: this.form.message,
        });
        this.clearForm();
        this.$gtm.trackEvent({
          event: "Button",
          category: "button",
          btnTitle: "Submit",
          label: "Submitted ",
          action: "Clicked on",
        });
        alert("Thank you, your message was sent successfully!");
      } catch (e) {
        console.error(e);
        alert("Error:  Your message could not be sent");
      }
      // ===== END API CALL =====
    },
    clearForm() {
      this.form.name = "";
      this.form.email = "";
      this.form.phone = "";
      this.form.message = "";
      this.form.checked = [];
    },
  },
};
</script>

<style scoped>
.contact-block .call {
  background: #f4f6fa;
}
.call-ttl {
  font-size: 22px;
  margin-bottom: 10px;
  line-height: normal;
  min-height: 60px;
}
.call {
  background: #f8f8f8;
  font-size: 18px;
  padding: 30px;
}

ul li {
  padding: 8px 0 8px 50px;
  position: relative;
}
ul li::after {
  content: "";
  background: #22b46e;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: 15px;
  top: 15px;
}
</style>
